import { createApp } from 'vue'
import App from './App.vue'
import { setupRouter } from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'

import { setupI18n } from './i18n'
import de from './locales/de.json'

const i18n = setupI18n({
  globalInjection: true,
  legacy: false,
  locale: 'de',
  fallbackLocale: 'de',
  messages: {
    de
  }
})

var dayjs = require('dayjs')

const router = setupRouter(i18n)

const app = createApp(App)

require('dayjs/locale/de')
require('dayjs/locale/fr')
app.config.globalProperties.$filters = {
  datetime(value) {
    let day = dayjs(value)
    if(!day.isValid()) return value
    return day.locale(i18n?.global?.locale?.value).format('ddd, DD.MM.YYYY, HH:mm')
  }
}


app.use(i18n)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .provide('axios', app.config.globalProperties.axios)
  .mount('#app')

