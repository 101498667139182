<template>
  
  <router-view/>  
</template>

<script setup>
import { useRoute } from 'vue-router'
import { ref } from 'vue'
const route = useRoute()
const trackingId = ref(route?.params?.trackingid)

</script>
<style lang="scss">
:root {
  --mainAccent: #EA7393;
  --mainColor: #4CAF50;
  --shadowColor: #444;
  --fontColor: rgb(92, 92, 92);
  --fontColorLight: rgb(140, 140, 140);
  --shadowColorLight: rgb(200, 200, 200);
  --cardBackgroundColor: white;
  --borderWidth: 0.2em;

  * {
    box-sizing: border-box;
  }
}
html, body {
  margin: 0;
  padding: 0;  
  font-family: sans-serif;
  color: var(--fontColor);
}

#app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size:.8em;
  min-height: 100vh;

  a {
    color:var(--fontColor);
  }

  .atrendo_logo {
    width: 100%;
    padding:1em;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    >img {
      width: 100%;
    }
  }
}

@media only screen and (min-width: 710px) {
  #app {    
    margin: 0 auto;
    >* {
      max-width: 1280px; 
    }   

    .atrendo_logo {
      flex-direction: row;
      text-align: right;
      img {
        max-width: 25em;
      }
      
    }
  }
}

.card {
  width: calc(100% - 2em);
  //background-color: var(--cardBackgroundColor);
  //box-shadow: 0px 0px 5px #888888;
  border-radius: .5em;
  margin-top: 1em;
  /* >* {
    margin: 1em;
  } */
}
</style>
